import ButtonPrimary from "@/components/templateux/button/button-primary";
import LoaderToggle from "@/components/templateux/loading/loader-toggle";

export default function SettingsSave({
    submitting_state
    ,actionText="Save changes"
    ,actionTextShort="Save changes"
    ,className=" whitespace-nowrap text-xl inline-flex items-center justify-center px-4 py-2 border border-blue-500 rounded-lg shadow-md  font-medium text-white bg-blue-500 hover:bg-blue-600 bg-gradient-to-b from-blue-600  to-blue-500 hover:from-brand hover:to-blue-500 text-center flex-0"
    ,button_type="submit" 
    ,changes=false
}) {

    var var_className = `${className}
                        ${changes 
                            ? " animate-pulse " 
                            : "" 
                        }
                        `

    return (<>
                <div className="mt-4">
                    <ButtonPrimary 
                    disabled={submitting_state} 
                    type={button_type}
                    className={var_className}
                    override_class={true}
                    >
                    <LoaderToggle
                        loadingstate={submitting_state}
                        actionText={actionText}
                        actionTextShort={actionTextShort}
                    />
                    </ButtonPrimary>
                </div>
            </>)
}