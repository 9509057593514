//import { hasData } from "./hasData";

export function textProcess(n) {

    return n;
    // var varn = n?.toString();

    // const date = new Date();

    // //april fools reference
    // if (date.getMonth() != 3 || date.getDate() != 1) return varn;

    // if (!hasData(varn)) return varn;

    // varn = varn?.replace(/[e]/gi, 'a');
    // varn = varn?.replace(/[i]/gi, 'e');
    // varn = varn?.replace(/[u]/gi, 'i');
    // varn = varn?.replace(/[a]/gi, 'o');
    // varn = varn?.replace(/[o]/gi, 'u');
    // varn = varn?.replace(/aa/gi, 'ay');
    // return varn
}