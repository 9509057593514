import { hasData } from "./hasData";
                  
export function IsJsonValid(str) {
    if (!hasData(str)) return false
    try {
        JSON.parse(str);
    } catch (e) {
        // console.log(e)
        return false;
    }
    return true;
  }

  export function validJson(str,alt) {
    if (IsJsonValid(str)) return JSON.parse(str);
    return alt
  }