import SvgJsx from "../svg/svg-jsx"

const GatingButton = ({}) => {
    return (<>
    <div 
                            className={` mt-2
                            border rounded-full  border-gray-200group-hover:border-white group-hover:shadow-md
                                      
                                        text-lg font-bold
                                        `} >
                            <div
                        
                                className={`px-4 py-2 my-auto flex items-center content-center`}
                            >


                                    <div className="flex-0 mr-2 ">
                                        <div className="relative h-5 w-5">
                                          <div className="absolute">
                                          <SvgJsx 
                                                type={'fill'}
                                                      
                                                icon={'heart-sm'}
                                                className={`group-hover:animate-ping h-5 w-5 text-red-600 group-hover:text-red-500`}
                                                title={'Contribute'}
                                            />
                                          </div>
                                          <div className="absolute">
                                            <SvgJsx 
                                                type={'fill'}
                                                      
                                                icon={'heart-sm'}
                                                className={`absolute h-5 w-5 text-red-600 group-hover:text-red-500`}
                                                title={'Contribute'}
                                            />
                                          </div>
                                        </div>
                                    </div>



                                    <div className="flex-0">
                                      Contribute
                                    </div>
                                
                              
                              </div>
                            </div>
    </>)
}

export default GatingButton