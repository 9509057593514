import { hasData } from "@/lib/utils/hasData";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import SvgJsx from "../svg/svg-jsx";
import { useInView } from "react-intersection-observer";

export default function ModalForm({
    title
    ,modal_content
    ,isOpen
    ,closeModal
}) {

    const [titleRef, isOnScreen] = useInView({
        /* Optional options */
        threshold: 0,
        initialInView: true,
        //onChange:(inView)=>{console.log("view changed",inView)}
      });
    // const isOnScreenRef = useRef(isOnScreen);
    // isOnScreenRef.current = isOnScreen;


    return (<>
                                   
                <Transition appear show={hasData(isOpen)} as={Fragment}>
                    <Dialog
                    as="div"
                    open={hasData(isOpen)}
                    className="fixed inset-0 z-100 overflow-y-auto bg-[rgb(0,0,0,0.65)]"
                    onClose={()=>closeModal()}
                    >

                    <div className={`fixed top-0 left-0 right-0 z-[10000]
                                bg-gray-900 px-2 py-1 shadow-lg border-b border-gray-800
                       ${isOnScreen ? "hidden" : "visible"}            
                    `}>
                        <div className="flex items-top content-top w-full">
                            <div className="flex-1 font-bold text-md sm:text-lg px-2 py-1">
                            {title}
                            </div>
                            <div className="flex-0">
                                <button
                                    className="justify-center cursor-pointer pt-2 pr-1 "
                                    onClick={closeModal}
                                    tabIndex={0}
                                >
                                    <SvgJsx 
                                                    type={'fill'}
                                                        
                                                    icon={'x-circle-sm'}
                                                    className={`group-hover:animate-ping h-5 w-5 
                                                                                                text-white hover:text-gray-200`}
                                                    title={'Close'}
                                                />
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="min-h-screen px-4 text-center  ">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="max-w-3xl
                                        inline-block w-full border transform p-2 sm:p-6 sm:my-8 overflow-hidden text-left align-middle transition-all shadow-modal rounded-2xl
                                        bg-gray-900 border-gray-700">
                          
                            <div className="sm:flex items-center content-center">
                                <div className="flex-1">
                               
                                   

                                    <div className="flex items-top content-top 
                                                    border-gray-700
                                    ">
                                        <div className="flex-1 font-bold text-md sm:text-lg px-2 pt-1">
                                            {title}
                                        </div>
                                        <div className="flex-0">
                                            <button
                                                className="justify-center cursor-pointer  "
                                                onClick={closeModal}
                                                ref={titleRef}
                                                tabIndex={0}
                                            >
                                                <SvgJsx 
                                                            
                                                                type={'fill'}
                                                                    
                                                                icon={'x-circle-sm'}
                                                                className={`group-hover:animate-ping h-5 w-5 
                                                                                                            text-white hover:text-gray-200`}
                                                                title={'Close'}
                                                            />
                                            </button>
                                        </div>
                                    </div>



                                </div>
                                
                            </div>


                           
                            
                            
                            <div className="mt-4 w-full content-center item-center flex">

                                {modal_content}

                            </div>
                            

                            
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>
    </>)
}