import { config } from "../config";
import { hasData } from "./hasData";

export function getAvatarUrl(
         avatar_url
        ,avatar_url_sfw
        ,bol_nsfw
        ,avatar_crdate
        ,override_url = ''
        ,user_id
    ) {


    const avatar_url_alt = hasData(avatar_url_sfw) 
                            ? avatar_url_sfw 
                            : hasData(override_url)
                               ? override_url
                               : config.avatar.url_missing

    return (validImage(avatar_url) && bol_nsfw !== 1)
            ? `${avatar_url}?c=${avatar_crdate}`
            : validImage(avatar_url_sfw)
                ? avatar_url_sfw
                : validImage(override_url)
                    ? override_url
                    : config.avatar.url_missing
    

}

function validImage(url) {

    if (!hasData(url)) return false
    if (url.indexOf('://') > 0 || url.indexOf('//') === 0 ) return true
    if (url.substring(0,1) == "/") return true
    return false
}