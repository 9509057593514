function ButtonPrimary({
  onClick = null,
  className = 'text-sm lg:text-md',
  children = null,
  type = null,
  disabled = false,
  form=null,
  override_class=false
}) {
  return (
    <button
      tabIndex={0}
      type={type}
      onClick={onClick}
      disabled={disabled}
      form={form}
      className={override_class 
        ? className
        : `${className} whitespace-nowrap inline-flex items-center justify-center px-4 py-1 border border-blue-500 rounded-lg shadow-md  font-medium text-white bg-blue-500 hover:bg-blue-600 bg-gradient-to-b from-blue-600  to-blue-500 hover:from-brand hover:to-blue-500 text-center flex-0`
      }
    >
      {children}
    </button>
  )
}
export default ButtonPrimary
