

export default function getMessage(message_type='failure',ssr_data_tribe) {

   

    if (message_type === 'noresults') return "Nothing to see here"
    if (ssr_data_tribe == null) return null

    const messages = ssr_data_tribe[`message_${message_type}`]?.split('\n');


    //choose one item from the array at random and return it
    if (Array.isArray(messages)) {
        if (messages?.length > 0) return messages[Math.floor(Math.random() * messages?.length)];
        return messages[0]
    } else {
        return null
    }
    
}