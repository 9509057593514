// USAGE
// isAdminUser(ssr_data_profile?.id             //user_id (eg owner_id)
//         ,ssr_data_profile?.bol_staff_help    //bol_staff_help
//         ,ssr_data_profile?.staff_json        //staff_json
//         ,ssr_data_profile?.bol_admin_help    //bol_admin_help
//         ,user["id"]                           //my_id
//         ,user["bol_admin"]         //global_admin
//         ,helptyp                     //SPECIFIC PERMISSIONS
//         )

import { STAFF_PERMS } from "../config"
import { getBitwise } from "./bitoperations"
import { hasData } from "./hasData"
import { IsJsonValid } from "./isJsonValid"

export default function isAdminUser(  user_id
                                    , bol_staff_help
                                    , staff_json
                                    , bol_admin_help
                                    , my_id
                                    , bol_admin
                                    , helptype
                                    ) {



    if (
        !hasData(my_id) 
        || my_id?.toString() == '0'
        || !hasData(user_id) 
        || user_id?.toString() == '0'
        ) {
            return false
    } 



    const validated_staff_json = IsJsonValid(staff_json) ? JSON.parse(staff_json) : []
    return user_id?.toString() == my_id?.toString() 
                        || (bol_staff_help?.toString() == "1" 
                            && validated_staff_json.filter((e)=> (e?.id?.toString() == my_id?.toString() 
                                                                  && (!hasData(helptype) 
                                                                        || getBitwise(e?.permissions,Object.keys(STAFF_PERMS))[helptype]
                                                                     )
                                                                ))?.length >= 1)
                        || (bol_admin_help?.toString() == "1" && parseInt(bol_admin) >= 1)
}

export function isAdminUserPermissions(  user_id
                                    , bol_staff_help
                                    , staff_json
                                    , bol_admin_help
                                    , my_id
                                    , bol_admin
                                    , helptype
                                    ) {



    if (
        !hasData(my_id) 
        || my_id?.toString() == '0'
        || !hasData(user_id) 
        || user_id?.toString() == '0'
        ) {
            return "none"
    } 

    if (my_id?.toString() == user_id?.toString()) return "all"



    if (
        bol_admin_help?.toString() == "1" && 
        parseInt(bol_admin) >= 1
        ) return "all";
    
    const validated_staff_json = IsJsonValid(staff_json) ? JSON.parse(staff_json) : []
    if (bol_staff_help?.toString() == "1" 
        && validated_staff_json.filter((e)=> (e?.id?.toString() == my_id?.toString() 
                                              && (!hasData(helptype) 
                                                    || getBitwise(e?.permissions,Object.keys(STAFF_PERMS))[helptype]
                                                    )
    ))?.length >= 1) {
        return validated_staff_json.filter((e)=> e?.id?.toString() == my_id?.toString())[0]?.permissions
    }
    
    return "none"
    
}