 /**
     * DEMO & SRC
     * https://www.youtube.com/watch?v=EVvZLfJtAK8
     * https://gist.github.com/prof3ssorSt3v3/ac03e6db33bf5d71a646320605b20e8c
    */

    
export function getBitwise(existing,arr) {
    var varstr = ''
    arr.map((e)=> {
        varstr = `${varstr},"${[e]}": ${!!(existing & bitval(arr.indexOf(e)))}`
    })

    return JSON.parse(`{${varstr.substring(1,varstr.length)}}`)
}

export function addBitwise(existing,newval) {
    return existing | newval; 
}

export function delBitwise(existing,newval) {
    return existing ^ newval;
}

export function strJsontoBinary(json) {
    var varstr = ''
    Object.keys(json).forEach(function(key) {
        varstr = `${json[key] ? '1' : '0'}${varstr}`;
    });
    return `0b${varstr}`
}

export function bitval(n) {
   if (n < 0) return 0
   return 2**n
}